import ConveyorClient from "../services/conveyor";
import * as Dom from "./dom";

export const conveyorClient = new ConveyorClient();

export function setTemplate(template: HTMLTemplateElement) {
  let content = (template.content.cloneNode(true) as HTMLElement).firstElementChild;

  for (let child of Dom.main.children) child.remove();
  Dom.main.append(content);

  return content;
}
